@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");
* {
  font-family: "Inter", sans-serif;
}

dialog {
  border: 1px solid #171a20;
  border-radius: 8px;
  top: 30%;
  background: #171a20;
  max-width: 360px;
}
input,
select {
  border-radius: 8px;
  border: 1px solid #303a4c;
  padding: 8px 10px;
  background: #111927;
  color: #545e70;
}
input::-webkit-input-placeholder {
  color: #545e70;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
label {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  margin-bottom: 2px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal h3 {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 253.333% */
  margin-top: -5px;
}
.modal h6 {
  margin-top: -5px;
  color: #8b8e94;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
.modal .btn {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  min-width: 100px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  border-radius: 8px;
  background: #665bff;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #665bff;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-right: 20px;
  height: 40px;
}
.modal .btn-outline{
  min-width: 100px;
  border-radius: 8px;
  background: none;
  color: #545E70;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #303A4C;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
}

.modal .btn:focus,
.btn.focus {
  outline: 0;
}

.btn-danger {
  background: #e74c3c;
  color: #ffffff;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 20px;
  height: 50px;
  background: #131722;
  color: white;
}

.top-bar h3 {
  font-weight: bold;
  font-size: 16px;
}

.btn-login {
  background: #665bff;
  color: white;
  font-size: 13px;
  padding: 6px 10px;
  border: 1px solid #665bff;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.modal {
  width: 300px;
}
.form-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.btn-report{
  margin-right: 30px;
  text-decoration: none;
  background: rgb(230, 28, 28);
  border: 1px solid rgb(230, 28, 28);
}

@media only screen and (max-width: 400px){
  .top-bar {
    padding: 2px 5px;
  }
}